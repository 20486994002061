import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { File, Store, VarietyPageQuery } from "../../graphql-types"
import Layout from "../../components/layout"
import StoreList from "../../partials/store/store-list"
import Container from "../../components/container"
import useLocations from "../../hooks/use-locations"
import useChains from "../../hooks/use-chains"
import Hero from "../../components/hero"

export default function VarietyPage({ data }: PageProps<VarietyPageQuery>) {
  const locations = useLocations()
  const chains = useChains()

  return (
    <Layout title={`${data.variety.title} vintage stores in Japan`} noGap>
      <div className={"flex w-full flex-col gap-8"}>
        <Hero
          title={data.variety.title}
          heroImage={data.heroImage as File}
          compact={data.variety.body === ""}
        >
          {data.variety.body && (
            <div
              className={"justify-center prose text-white"}
              dangerouslySetInnerHTML={{ __html: data.variety.body }}
            />
          )}
        </Hero>
        <Container className={"justify-center"}>
          <StoreList
            locations={locations}
            chains={chains}
            stores={data.variety.stores as Store[]}
          />
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query VarietyPage($id: String!) {
    variety(id: { eq: $id }) {
      title
      slug
      body
      stores {
        ...StoreCommon
        ...StoreVarieties
        ...StoreChain
        ...StoreTileImages
        ...StoreLocation
      }
    }
    heroImage: file(relativePath: { eq: "opshop.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
